import React from "react"
import Header from "../components/header";
import Layout from "../components/layout";
import { Link } from 'gatsby'

export default function Contact() {
  return (
    <Layout>
      <div className="p-4 sm:p-10 md:p-16 bg-flamingo-100 h-full">
        <Header headerText="Kontact" />
        <p>Dziękujemy za wiadomość. Odpiszemy do państwa w ciągu 24-48 godzin.</p>
        <Link to="/"> &larr; Powrót</Link>
      </div>
    </Layout>
  )
}
